<template>
  <div id="login-page">
    <div class="login-container">
      <div class="login-container__form">
        <div class="header-login">
          <h5 class="ml-3 font-weight-light">New Password</h5>
        </div>

        <form @submit.prevent="passChange">
          <div class="login-container__form-inner">
            <p style="font-size: 15px; text-align:justify">
              Please enter and confirm your password
            </p>

            <hr />

            <small class="text-danger" v-if="isNotSame">
              To proceed, please make sure New Password and Confirm Password field is equivalent
            </small>

            <b-form-group class="mt-2">
              <label for="new-password"> New Password </label>
              <b-form-input
                id="password"
                type="password"
                v-model="newPassword"
                size="sm"
                placeholder="Enter new password"
                :disabled="loading"
              />
            </b-form-group>

            <b-form-group>
              <label for="confirm-password"> Confifrm New Password </label>
              <b-form-input
                id="confirm-password"
                type="password"
                v-model="confirmPassword"
                size="sm"
                placeholder="Enter password confirmation"
                :disabled="loading"
              />
            </b-form-group>

            <div class="buttons mb-4">
              <button
                type="submit"
                class="btn btn-sm btn-pompe-primary login-button mt-2 mb-3"
                :loading="loading"
                :disabled="loading || !readyToSubmit"
              >
                <span v-if="!loading"> Submit new password </span>
                <span v-else>
                  <b-spinner small label="Small Spinner"></b-spinner>
                </span>
              </button>

              <br />

              <small>
                <a
                  href="javascript:void(0)"
                  class="anchor-link"
                  @click="goBack"
                >
                  Go back
                </a>
              </small>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data: () => {
    return {
      newPassword: '',
      confirmPassword: '',
      loading: false,
    };
  },
  computed: {
    readyToSubmit() {
      return this.newPassword === this.confirmPassword && (this.newPassword && this.confirmPassword);
    },
    isNotSame() {
      return this.newPassword !== this.confirmPassword && this.confirmPassword;
    },
  },
  methods: {
    goBack() {
      if (this.$cookies.get("token")) {
        this.$router.push({ name: 'profilePage' });
        return;
      }

      this.$router.push({ name: 'login' }); 
    },
    passChange() {
      this.loading = true;

      const id = this.$route?.query?.helperId;
      axios.put(this.$apiEndpoints.passwordReset + `?helperId=${id}`, {
        '@class': 'ch.galliard.pompe.api.dto.pages.User',
        password: this.newPassword 
      }).then(() => {
        if (this.$cookies.get("token")) {
          this.$router.push({ name: 'profilePage' });
        } else {
          this.$router.push({ name: 'login' });
        }
      }).catch(e => {
        this.$vToastify.error(e.response.data.errors[0].message || 'Please contact administrator');
      }).finally(() => {
        this.loading = false;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/color.scss";

.login-container {
  padding-top: 3em;
  padding-bottom: 2.5em;

  .header-login {
    background-color: $primary-ui;
    color: #fff;
    height: 50px;
    padding: 0.75em 0 1em 0.5em;
  }

  &__form {
    display: center;
    justify-content: start;
    align-content: left;
    border: 1px solid #e4e4e4;
    // box-shadow: 2px 2px 5px #c9c9c9;
    width: 600px;
    margin: auto;
    // background-color: #E4E4E4;

    &-inner {
      width: 400px;
      padding-left: 1.5em;
      padding-top: 1em;
    }

    .login-button {
      width: 200px;
    }

    .links {
      font-size: 13px;
      padding-bottom: 20px;

      .no-account {
        margin-top: 1em;
      }
    }
  }
}
</style>
